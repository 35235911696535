<template>
  <div class="star-pop-edit-container">
    <a-modal
      title="编辑余额"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      width="500px"
    >

    <div class="time-select">
      星光余额：
      <div class="money">{{row.starlight }}</div>
    </div>

    <div class="time-select">
      操作类型：

      <a-radio-group name="radioGroup" v-model="type" >
        <a-radio :value="0">
          奖励星光
        </a-radio>
        <a-radio :value="1">
          扣除星光
        </a-radio>
      </a-radio-group>
    </div>
    <div class="time-select">
      星光数量：
      <a-input-number :min="0" v-model="starlight" style="width:180px" ></a-input-number> 
    </div>

    </a-modal>
  </div>
</template>

<script>
 
export default {
  data() {
    return {
      confirmLoading: false,
      starlight:0, //奖励
      balance:0, //余额
      isEdit:false,
      type:0,
      // url:'v2/starShine/edit' //公会编辑余额
    };
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    row:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    url:{
      type:String,
      default:'v1/user/starlight/edit'
    }
  },
  computed: {},
  mounted() {
  },
  methods: {
    handleOk(e) {
      let starlight=0
      if(this.type==1) {
        starlight=this.starlight*-1
      }else{
        starlight=this.starlight
      }
      this.confirmLoading=true
      this.$api.post(this.url,{
        uid:this.row.uid,
        starlight
      }).then(res=>{
        this.confirmLoading=false
        if(res.code==200){
          this.$message.success('编辑成功')
          this.$emit("handleOk");
        }else{
          this.$message.error(res.message)
        }
      })
    },
    handleCancel(e) {
      this.$emit("handleCancel");
    }
  },
};
</script>

<style lang="less" scoped>
.star-pop-edit-container {

}

  .time-select{
    display: flex;
    align-items: center;
    height: 50px;
    .money{
      color: #199AEC;
    }
  }


</style>
